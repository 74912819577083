import { Reveal } from '../ui/components/Reveal';
import letters from '../assets/letters.json';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSound } from 'use-sound';

const timePerLetter = 0.1;

export const Letter = () => {
  const [timeOnPage, setTimeOnPage] = useState(0);
  const volume = 0.1;
  const volumeRange = 0.05;

  const [play, { sound }] = useSound(
    '/audio/effects/typewriter-single-key.mp3',
    { volume }
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeOnPage((prev) => prev + 1 / 20);
    }, 1000 / 20);

    return () => clearInterval(interval);
  }, []);

  const { letter } = useMemo(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const standardisedDate = `${year}-${month}-${day}`;
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const nonBreakingSpace = '\u00A0';
    const monthName = monthNames[today.getMonth()];
    const dateFormatted = `${monthName}${nonBreakingSpace}${day}`;

    const todaysLetter = (letters as any)[standardisedDate] || 'nothing';

    const containsLineBreak = todaysLetter.includes('\n');
    const optionalIndent = containsLineBreak ? '' : '\t';

    const letterWithDate = `${dateFormatted}.\n\n${optionalIndent}${todaysLetter}`;

    return { letter: letterWithDate };
  }, []);

  const charactersDisplayed = Math.min(
    Math.floor(timeOnPage / timePerLetter),
    letter.length
  );

  const lastCharaterAmount = useRef(0);

  useEffect(() => {
    // is last character a space, if so, don't play
    const lastWrittenCharacter = letter[charactersDisplayed - 1];
    const isLastWrittenCharacterSpace = /\s/.test(lastWrittenCharacter);

    if (
      charactersDisplayed > lastCharaterAmount.current &&
      !isLastWrittenCharacterSpace
    ) {
      sound?.volume(Math.random() * volumeRange + volume);
      play();
      lastCharaterAmount.current = charactersDisplayed;
    }
  }, [charactersDisplayed]);

  return (
    <>
      <style>
        {`
          pre span {
            opacity: 0;
          }
          pre span.appear {
            animation: fadeIn 0.05s linear forwards;
          }

          @keyframes fadeIn {
            0% {
              opacity: 0;
              filter: blur(4px);
            }
            100% {
              opacity: 1;
              filter: blur(0px);
            }
          }
        `}
      </style>
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'start',
          flexDirection: 'column',
          maxWidth: 400,
          margin: 'auto',
          rowGap: 16,
          userSelect: 'none',
          lineHeight: 1.8,
          wordSpacing: '0.25em',
          padding: 24,
          boxSizing: 'border-box',
          pointerEvents: 'none',
        }}
      >
        <Reveal size={80} inactiveOpacity={0.5} activeOpacity={1}>
          <pre
            style={{
              fontSize: '0.75rem',
              fontFamily: 'SupplyMono, serif',
              wordWrap: 'break-word',
              whiteSpace: 'pre-wrap',
            }}
          >
            {letter.split('').map((char, index) => (
              <span
                key={index}
                className={index < charactersDisplayed ? 'appear' : ''}
              >
                {char}
              </span>
            ))}
          </pre>
        </Reveal>
      </div>
    </>
  );
};
