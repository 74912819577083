import create from 'zustand';

type WalkmanStore = {
  isDismissed: boolean;
  dismiss: () => void;
  isDisappeared: boolean;
  disappear: () => void;
};

export const useWalkmanStore = create<WalkmanStore>((set) => ({
  isDismissed: false,
  dismiss: () => set({ isDismissed: true }),
  isDisappeared: false,
  disappear: () => set({ isDisappeared: true }),
}));
