import { rgba } from 'polished';
import { FC, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { useSound } from 'use-sound';
import { useCalendar } from '../../lib/calendar';
import { ContextualPopup, Divider, Typography } from '../../ui';
import { WorldMap } from '../../ui/components/ContextualPopup/assets/WorldMap';

const MapIndicator: FC = () => {
  return <MapIndicatorRoot />;
};
const mapIndicatorAnimation = keyframes`
  0% {
    left: 0;
  }
  100% {
    left: 100%;
  }
`;
const MapIndicatorRoot = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 1px;
  background: linear-gradient(
    to bottom,
    ${({ theme }) => rgba(theme.foreground, 0.1)} 0%,
    ${({ theme }) => rgba(theme.foreground, 0.25)} 40%,
    ${({ theme }) => rgba(theme.foreground, 0.25)} 60%,
    ${({ theme }) => rgba(theme.foreground, 0.1)} 100%
  );
  animation: ${mapIndicatorAnimation} 8s ease-in-out infinite;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 100%;
    background: radial-gradient(
      ellipse at right,
      ${({ theme }) => rgba(theme.foreground, 0.1)} 0%,
      ${({ theme }) => rgba(theme.foreground, 0)} 50%
    );
  }
`;

export const LocationContextualPopup = ({
  onOpenChange,
}: {
  onOpenChange: (open: boolean) => void;
}) => {
  const { currentCalendarLocation } = useCalendar();
  const [play] = useSound('/audio/effects/sonar-sound-effect.mp3', {
    volume: 0.1,
  });

  const firstRenderTimestamp = useRef(0);

  useEffect(() => {
    firstRenderTimestamp.current = Date.now();
  }, []);

  // play the audio effect every 8 seconds and delay 3.5 seconds
  useEffect(() => {
    const delayFromFirstRender = Date.now() - firstRenderTimestamp.current;
    const delay = 3500 - delayFromFirstRender - 200;
    const interval = 8000;
    let intervalId: NodeJS.Timeout;

    const timeout = setTimeout(() => {
      intervalId = setInterval(() => {
        play();
      }, interval);
    }, delay);

    return () => {
      clearTimeout(timeout);
      clearInterval(intervalId);
    };
  }, [play]);

  if (!currentCalendarLocation) {
    return null;
  }

  return (
    <ContextualPopup
      behaviour="click"
      onOpenChange={onOpenChange}
      handle={
        <Handle>
          <MapIndicator />

          <HandleMap>
            <div
              style={{
                mixBlendMode: 'hue',
                transform: 'scale(1.25)',
              }}
            >
              <WorldMap
                lat={currentCalendarLocation.lat}
                long={currentCalendarLocation.long}
                pulsating={true}
                mapPathOpacity={0.5}
                mapPathStrokeWidth={0.8}
              />
            </div>
          </HandleMap>
        </Handle>
      }
    >
      <ContextualPopup.WorldMap
        lat={currentCalendarLocation.lat}
        long={currentCalendarLocation.long}
      />

      <Divider.Horizontal />

      <ContextualPopup.Section>
        <Typography.Caption opacity={0.5}>
          The night sky you're seeing is the same sky I see when I look up. It's
          adjusted to my exact location and synchronized with the current phase
          of Earth's rotation.
          <br />
          <br />
          The space imagery is sourced from NASA.
        </Typography.Caption>
      </ContextualPopup.Section>
    </ContextualPopup>
  );
};

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const Handle = styled.div`
  position: relative;
  height: 80px;
  width: 240px;
  overflow: hidden;
  opacity: 0;
  animation: ${fadeIn} 2s ease-in-out;
  animation-delay: 8s;
  animation-fill-mode: forwards;
  mask: radial-gradient(circle, black 20%, transparent);
  border: 1px solid ${({ theme }) => rgba(theme.foreground, 0.5)};
  border-left: 0;
  border-right: 0;
  display: flex;
  align-items: start;
  justify-content: center;
`;

const backgroundPosition = keyframes`
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 240px 0;
  }
`;

const HandleMap = styled.div`
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.75), transparent);
    background-repeat: repeat;
    background-size: 240px 80px;
    background-position: 0 0;
    animation: ${backgroundPosition} 8s ease-in-out infinite;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
`;
