import React from 'react';
import Head from 'next/head';

export const config = {
  title: `Ondřej Bárta - Barcelona based creator with full stack engineering background`,
  description: ``,
  author: `@bartaxyz`,
};

export interface SEOProps {
  title?: string;
  titleOverride?: string;
  description?: string;
  lang?: string;
  meta?: object[];
  script?: object[];
  featuredImage?: string;
  children?: React.ReactNode;
}

const SEO: React.FC<SEOProps> = ({
  title = config.title,
  titleOverride,
  description = config.description,
  lang = 'en',
  meta = [],
  script = [],
  featuredImage,
  children,
}) => {
  const afterTitle = `${config.title}`;
  const finalTitle = titleOverride
    ? titleOverride
    : title
    ? `${title} | ${afterTitle}`
    : afterTitle;

  return (
    <Head>
      <title>{finalTitle}</title>
      <meta name="description" content={description} />

      <link
        rel="alternate"
        type="application/rss+xml"
        title="Please Wait To Be Seated"
        href="https://ondrejbarta.com/rss/feed.xml"
      />

      <meta property="og:title" content={finalTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={title} />
      <meta property="og:image" content={featuredImage} />
      <meta name="twitter:creator" content="@bartaxyz" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={config.author} />
      <meta name="twitter:title" content={finalTitle} />
      <meta name="twitter:description" content={description} />

      <meta property="msapplication-TileColor" content="#000000" />
      <meta property="theme-color" content="#ffffff" />

      <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
      <meta name="theme-color" content="#000000"></meta>

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/favicon/apple-touch-icon.png?v=A07gKj6BqM1"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon/favicon-32x32.png?v=A07gKj6BqM1"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon/favicon-16x16.png?v=A07gKj6BqM1"
      />
      <link rel="manifest" href="/favicon/site.webmanifest?v=A07gKj6BqM1" />
      <link
        rel="mask-icon"
        href="/favicon/safari-pinned-tab.svg?v=A07gKj6BqM1"
        color="#000000"
      />
      <link rel="shortcut icon" href="/favicon/favicon.ico?v=A07gKj6BqM1" />

      {/* scripts */}
      {script.map(({ ...props }, index) => (
        <script key={index} {...props} />
      ))}

      {children}
    </Head>
  );
};

export default SEO;
