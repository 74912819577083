import { format, formatDistanceToNowStrict } from 'date-fns';
import { memo } from 'react';
import { HeartRateChart } from '../HeartRateChart/HeartRateChart';
import { useHealth } from '../../hooks/useHealth';
import { ContextualPopup, Divider, Flex, Typography } from '../../ui';
import { ECGChart } from '../ECGChart/ECGChart';
import { HeartRateIndicator } from '../HeartRateIndicator/HeartRateIndicator';
import { HeartRateContextualPopupHandle } from './HeartRateContextualPopupHandle';

export const HeartRateContextualPopup = memo(() => {
  const { heartRate, heartRateHistory, lastSync, source, isLoading, isError } =
    useHealth();

  if (isLoading || isError) {
    return null;
  }

  return (
    <ContextualPopup
      behaviour="click"
      handle={<HeartRateContextualPopupHandle />}
      onOpenChange={(open: boolean) => {
        if (open) {
          (DeviceMotionEvent as any).requestPermission?.();
        }
      }}
    >
      <ContextualPopup.Section>
        <Flex justifyContent="space-between">
          <Flex flexDirection="column">
            <Typography.Caption size="L">Heart Rate</Typography.Caption>

            <Flex gap={4} alignItems="center">
              <HeartRateIndicator heartRate={heartRate} />

              <Typography.Caption opacity={0.5}>
                {Math.floor(heartRate ?? 0)} BPM
              </Typography.Caption>
            </Flex>
          </Flex>

          <HeartRateChart
            heartRateHistory={
              heartRateHistory ??
              Array.from({ length: 10 }, (_, i) => ({
                date: new Date(
                  Date.now() - (i + Math.random() * 10) * 1000 * 60 * 60
                ),
                value: 0,
              }))
            }
            heartRateExtrapolation={heartRate ?? 0}
          />
        </Flex>
      </ContextualPopup.Section>
      <Divider.Horizontal />

      <ContextualPopup.Section>
        <Flex justifyContent="space-between">
          <Flex flexDirection="column">
            <Typography.Caption size="L">Electrocardiogram</Typography.Caption>
            <Typography.Caption opacity={0.5}>
              No signs of {heartRate ? 'atrial fibrilation' : 'life'}
            </Typography.Caption>
          </Flex>

          {/* Random ECG chart */}
          <ECGChart heartRate={heartRate} />
        </Flex>
      </ContextualPopup.Section>

      <Divider.Horizontal />

      <ContextualPopup.Section>
        <Flex justifyContent="space-between" alignItems="center">
          <Flex flexDirection="column" alignItems="start">
            <Typography.Caption size="L">
              Last Synchronization
            </Typography.Caption>

            {source && source.toLowerCase() !== 'unknown' && (
              <Typography.Caption opacity={0.5} textAlign="left">
                from{' '}
                {source.startsWith('iOS')
                  ? 'iPhone'
                  : source.startsWith('watchOS')
                  ? 'Apple Watch'
                  : 'Unknown device'}
              </Typography.Caption>
            )}
          </Flex>

          {lastSync ? (
            <>
              <Flex flexDirection="column" alignItems="end">
                <Typography.Caption size="L">
                  {formatDistanceToNowStrict(lastSync, {
                    addSuffix: true,
                  })}
                </Typography.Caption>

                <Typography.Caption opacity={0.5} textAlign="left">
                  {format(lastSync, 'dd. MMM. yyyy, p')}
                </Typography.Caption>
              </Flex>
            </>
          ) : (
            <Typography.Caption opacity={0.5} textAlign="left">
              None in the last 24 hours
            </Typography.Caption>
          )}
        </Flex>
      </ContextualPopup.Section>

      <Divider.Horizontal />

      <ContextualPopup.Section>
        <Typography.Caption opacity={0.5} textAlign="left">
          This is my (almost) real-time heart rate synchronised directly with my
          Apple Watch as frequently as the system allows. This data is
          extrapolated from recent historical data to provide a continuous
          heartbeat prediction.
          <br />
          <br />
          Fun fact; the beating heart animation is also synchronised with my
          real heart rate.
        </Typography.Caption>
      </ContextualPopup.Section>
    </ContextualPopup>
  );
});
