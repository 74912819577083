/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import { useGLTF } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { useEffect, useRef } from 'react';
import { useTheme } from 'styled-components';
import * as THREE from 'three';
import { GLTF } from 'three-stdlib';
import useSound from 'use-sound';
import { useWalkmanStore } from './useWalkmanState';

type GLTFResult = GLTF & {
  nodes: {
    Walkman: THREE.Mesh;
    Button4_low001: THREE.Mesh;
  };
  materials: {
    Walkman: THREE.MeshStandardMaterial;
    ['Walkman.Button']: THREE.MeshStandardMaterial;
  };
};

const initialButtonEmissiveIntensity = 3;

export const Walkman = (props: JSX.IntrinsicElements['group']) => {
  const { isDismissed, dismiss, disappear } = useWalkmanStore();
  const { accent } = useTheme();
  const { nodes, materials } = useGLTF('/3d/Walkman.glb') as GLTFResult;
  const [playPressDown] = useSound(
    '/audio/effects/tape-player-press-down.wav',
    { volume: 1 }
  );
  const [playPressUp] = useSound('/audio/effects/tape-player-press-up.wav', {
    volume: 1,
  });
  const [playNoise, { duration: noiseDuration }] = useSound(
    '/audio/fm-radio-static.mp3',
    {
      volume: 1,
      interrupt: true,
    }
  );

  const buttonRef = useRef<THREE.Mesh>(null);

  const buttonMaterial = materials['Walkman.Button'];

  buttonMaterial.color.set(accent);
  buttonMaterial.roughness = 0.5;
  buttonMaterial.metalness = 1;
  buttonMaterial.emissive.set('red');
  buttonMaterial.colorWrite = true;
  buttonMaterial.visible = true;
  buttonMaterial.needsUpdate = true;

  const buttonColorRef = useRef('white');
  const buttonPositionRef = useRef(new THREE.Vector3());
  const buttonEmissiveIntensityRef = useRef(0);

  useEffect(() => {
    buttonEmissiveIntensityRef.current = 0;

    setTimeout(() => {
      buttonEmissiveIntensityRef.current = initialButtonEmissiveIntensity;
    }, 3000);
  }, []);

  useEffect(() => {
    if (isDismissed) {
      buttonPositionRef.current.set(0, 0, 0);
      buttonEmissiveIntensityRef.current = 0;
    }
  }, [isDismissed]);

  const onPointerDown = () => {
    playPressDown();

    buttonPositionRef.current.set(-0.05, 0, 0);
    if (isDismissed) return;
    buttonEmissiveIntensityRef.current = initialButtonEmissiveIntensity;
  };

  const onPointerUp = () => {
    playPressUp();
    playNoise();

    setTimeout(() => {
      disappear();
    }, (noiseDuration || 5000) / 2);

    buttonPositionRef.current.set(0, 0, 0);
    if (isDismissed) return;
    buttonEmissiveIntensityRef.current = initialButtonEmissiveIntensity;
    dismiss();
  };

  const onPointerOver = () => {
    if (isDismissed) return;
    buttonEmissiveIntensityRef.current = 5;
  };

  const onPointerOut = () => {
    if (isDismissed) return;
    buttonEmissiveIntensityRef.current = initialButtonEmissiveIntensity;
  };

  useFrame(() => {
    if (!buttonRef.current) return;

    buttonRef.current.position.lerp(buttonPositionRef.current, 0.25);
    buttonMaterial.emissiveIntensity = THREE.MathUtils.lerp(
      buttonMaterial.emissiveIntensity,
      buttonEmissiveIntensityRef.current,
      0.01
    );
  });

  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Walkman.geometry} material={materials.Walkman} />
      <mesh
        ref={buttonRef}
        geometry={nodes.Button4_low001.geometry}
        material={buttonMaterial}
        onPointerOver={onPointerOver}
        onPointerDown={onPointerDown}
        onPointerUp={onPointerUp}
        onPointerOut={onPointerOut}
      />
    </group>
  );
};

useGLTF.preload('3d/Walkman.glb');
