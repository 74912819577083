import { useControls } from 'leva';
import { rgba } from 'polished';
import { useEffect, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';

export const AudioPlayer = () => {
  const { width, height } = useControls('audio', {
    width: { value: 120, min: 1, max: 1000 },
    height: { value: 32, min: 1, max: 1000 },
  });

  const theme = useTheme();
  const [isPlaying, setIsPlaying] = useState(false);

  const audioRef = useRef<HTMLAudioElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const togglePlay = () => {
    if (!audioRef.current) {
      return;
    }

    if (isPlaying) {
      /* audioRef.current.pause();
      setIsPlaying(false); */
    } else {
      audioRef.current.play();
      setIsPlaying(true);
    }
  };

  /* On first render, toggle play */
  useEffect(() => {
    togglePlay();
  }, []);

  const triggeredOnFirstPlay = useRef(false);

  useEffect(() => {
    const audio = audioRef.current;
    const canvas = canvasRef.current;
    if (!canvas || !audio) return;

    const ctx = canvas.getContext('2d');

    if (!ctx) return;
    if (!isPlaying) return;
    if (triggeredOnFirstPlay.current) return;
    triggeredOnFirstPlay.current = true;

    // Set up Web Audio API for visualization
    const audioContext = new AudioContext();
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 2048;
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const source = audioContext.createMediaElementSource(audio);

    source.connect(analyser);
    analyser.connect(audioContext.destination);

    const devicePixelRatio = window.devicePixelRatio || 1;
    canvas.width = width * devicePixelRatio;
    canvas.height = height * devicePixelRatio;
    canvas.style.width = `${width}px`;
    canvas.style.height = `${height}px`;
    ctx.scale(devicePixelRatio, devicePixelRatio);

    // Draw the waveform
    const drawWaveform = () => {
      analyser.getByteTimeDomainData(dataArray);

      ctx.clearRect(0, 0, width, height);

      ctx.lineWidth = 1;
      ctx.strokeStyle = rgba(theme.foreground, 0.5);
      ctx.beginPath();

      const sliceWidth = (width * 1.0) / bufferLength;
      let x = 0;

      for (let i = 0; i < bufferLength; i++) {
        const v = dataArray[i] / 128.0;
        const y = (v * height) / 2;

        if (i === 0) {
          ctx.moveTo(x, y);
        } else {
          ctx.lineTo(x, y);
        }

        x += sliceWidth;
      }

      ctx.lineTo(width, height / 2);
      ctx.stroke();

      requestAnimationFrame(drawWaveform);
    };

    drawWaveform();

    return () => {
      audioContext?.close();
    };
  }, [isPlaying]);

  return (
    <Root onClick={togglePlay} isPlaying={isPlaying}>
      <canvas
        ref={canvasRef}
        width={width}
        height={height}
        style={{
          width: `${width}px`,
          height: `${height}px`,
        }}
      />

      <audio ref={audioRef} src="/audio/daniel.mp3" />
    </Root>
  );
};

const Root = styled.div<{ isPlaying: boolean }>`
  opacity: ${({ isPlaying }) => (isPlaying ? 1 : 0)};
  transition: opacity 2s ease-in-out;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: -1px;
    height: 100%;
    width: 1px;
    background: linear-gradient(
      to bottom,
      transparent,
      ${({ theme }) => rgba(theme.foreground, 0.5)} 35%,
      ${({ theme }) => rgba(theme.foreground, 0.5)} 65%,
      transparent
    );
  }

  &::after {
    left: auto;
    right: -1px;
  }
`;
