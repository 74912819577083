/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import { useGLTF } from '@react-three/drei'
import * as THREE from 'three'
import { GLTF } from 'three-stdlib'

type GLTFResult = GLTF & {
  nodes: {
    Tree_2_Tree_2Mat_0: THREE.Mesh
    Tree_3_Tree_3Mat_0: THREE.Mesh
    Tree_4_Tree_4Mat_0: THREE.Mesh
  }
  materials: {
    Tree_2Mat: THREE.MeshStandardMaterial
    Tree_3Mat: THREE.MeshStandardMaterial
    Tree_4Mat: THREE.MeshStandardMaterial
  }
}

export function PalmTree(props: JSX.IntrinsicElements['group']) {
  const { nodes, materials } = useGLTF('3d/PalmTree.glb') as GLTFResult
  return (
    <group {...props} dispose={null}>
      <group scale={0.01}>
        <mesh geometry={nodes.Tree_2_Tree_2Mat_0.geometry} material={materials.Tree_2Mat} />
        <mesh geometry={nodes.Tree_3_Tree_3Mat_0.geometry} material={materials.Tree_3Mat} />
        <mesh geometry={nodes.Tree_4_Tree_4Mat_0.geometry} material={materials.Tree_4Mat} />
      </group>
    </group>
  )
}

useGLTF.preload('3d/PalmTree.glb')
