import * as d3 from 'd3';
import { rgba } from 'polished';
import { FC } from 'react';
import { useSystemTheme } from '../../layouts/utils/useSystemTheme';
import { Flex, Typography } from '../../ui';

export type HeartRateHistory = {
  date: Date;
  value: number;
}[];

interface HeartRateChartProps {
  heartRateHistory: HeartRateHistory;
  heartRateExtrapolation?: HeartRateHistory[number]['value'];
}

export function filterHeartRateHistory(
  heartRateHistory: HeartRateHistory
): HeartRateHistory {
  const filteredHistory: HeartRateHistory = [];

  heartRateHistory.forEach((entry) => {
    // Get the most recent entry from filteredHistory
    const lastEntry = filteredHistory[filteredHistory.length - 1];

    // Check if the lastEntry exists and if it's in the same minute as the current entry
    if (
      lastEntry &&
      Math.abs(entry.date.getTime() - lastEntry.date.getTime()) < 60 * 1000
    ) {
      return; // Skip if it's within the same minute
    }

    // Otherwise, add the current entry to the filtered list
    filteredHistory.push(entry);
  });

  return filteredHistory;
}

export const HeartRateChart: FC<HeartRateChartProps> = ({
  heartRateHistory: heartRateHistoryWithoutExtrapolation,
  heartRateExtrapolation,
}) => {
  const theme = useSystemTheme();

  const heartRateHistoryLimited = filterHeartRateHistory(
    heartRateHistoryWithoutExtrapolation
  );

  const maxDateWithoutExtrapolation = Math.max(
    ...heartRateHistoryLimited.map((item) => item.date.getTime())
  );

  const heartRateHistory = heartRateExtrapolation
    ? [
        ...heartRateHistoryLimited,
        {
          date: new Date(maxDateWithoutExtrapolation + 2 * 60000),
          value: heartRateExtrapolation,
        },
      ]
    : heartRateHistoryLimited;

  const maxHeartRate = Math.max(...heartRateHistory.map((item) => item.value));
  const minHeartRate = Math.min(...heartRateHistory.map((item) => item.value));

  const maxDate = Math.max(
    ...heartRateHistory.map((item) => item.date.getTime())
  );
  const minDate = Math.min(
    ...heartRateHistory.map((item) => item.date.getTime())
  );

  const topMarginPercentage = 0.1;
  const bottomMarginPercentage = 0.2;

  const chartDisplayMax = maxHeartRate + maxHeartRate * topMarginPercentage;
  const chartDisplayMin = minHeartRate - minHeartRate * bottomMarginPercentage;

  const chartWidthEndMargin = 2;

  const chartWidth = 120 - chartWidthEndMargin;
  const chartHeight = 32;

  const barWidth = 1;

  // Calculating the scale for y-axis
  const scaleY = d3
    .scaleLinear()
    .domain([chartDisplayMin, chartDisplayMax])
    .range([chartHeight, 0])
    .clamp(true);

  // Calculating the scale for x-axis
  const scaleX = d3
    .scaleLinear()
    .domain([minDate, maxDate])
    .range([0, chartWidth - barWidth]);

  return (
    <Flex gap={4}>
      <Flex flexDirection="column" alignItems="flex-end">
        <Typography.Caption size="S" opacity={0.5}>
          ↑ {Math.floor(maxHeartRate)} BPM
        </Typography.Caption>
        <Typography.Caption size="S" opacity={0.5}>
          ↓ {Math.floor(minHeartRate)} BPM
        </Typography.Caption>
      </Flex>

      <svg
        width={chartWidth + chartWidthEndMargin}
        height={chartHeight}
        viewBox={`0 0 ${chartWidth + chartWidthEndMargin} ${chartHeight}`}
      >
        <defs>
          <linearGradient id="accent-line-gradient" x1="1" y1="0" x2="0" y2="0">
            <stop offset="0%" stopColor={rgba('white', 1)} />
            <stop offset="75%" stopColor={rgba('white', 0.75)} />
            <stop offset="100%" stopColor={rgba('white', 0)} />
          </linearGradient>

          <mask id="accent-line-gradient-mask">
            <rect
              width={chartWidth + chartWidthEndMargin}
              height={chartHeight}
              fill="url(#accent-line-gradient)"
            />
          </mask>
        </defs>

        <g mask={`url(#accent-line-gradient-mask)`}>
          {/* Line indicating the max value */}
          <line
            x1={0}
            y1={scaleY(maxHeartRate)}
            x2={chartWidth + chartWidthEndMargin}
            y2={scaleY(maxHeartRate)}
            stroke={theme.foreground}
            strokeWidth="1"
            opacity={0.2}
            strokeDasharray="3"
          />
          {/* Line indicating the min value */}
          <line
            x1={0}
            y1={scaleY(minHeartRate)}
            x2={chartWidth + chartWidthEndMargin}
            y2={scaleY(minHeartRate)}
            stroke={theme.foreground}
            strokeWidth="1"
            opacity={0.2}
            strokeDasharray="3"
          />
          {/* Line between the bars */}
          <path
            d={
              d3
                .line()
                .x((d) => scaleX(d[0]))
                .y((d) => scaleY(d[1]))
                .curve(d3.curveBumpX)(
                heartRateHistory.map((d) => [d.date.getTime(), d.value])
              ) ?? ''
            }
            fill="none"
            stroke={theme.accent}
            strokeWidth={1}
            opacity={1}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          {/* Area below the line */}
          <path
            d={
              d3
                .line()
                .x((d) => scaleX(d[0]))
                .y((d) => scaleY(d[1]))
                .curve(d3.curveBumpX)([
                ...heartRateHistory.map((d) => [d.date.getTime(), d.value]),
                [
                  // last date
                  heartRateHistory[heartRateHistory.length - 1].date.getTime() +
                    10000,
                  // end of the chart
                  chartWidth + chartWidthEndMargin,
                ],
                [maxDate + 1, chartDisplayMin],
                [minDate - 1, chartDisplayMin],
              ] as [number, number][]) ?? ''
            }
            // gradient
            fill={`url(#area-gradient)`}
            fillOpacity={0.2}
            stroke="none"
          />
          {heartRateHistory.map((d, i) => {
            // skip first
            if (i === 0) {
              return null;
            }

            const isLast = i === heartRateHistory.length - 1;

            return (
              <rect
                key={i}
                x={scaleX(d.date.getTime())}
                y={scaleY(d.value) + (isLast ? 4 : 3)}
                width={barWidth}
                height={8}
                fill="url(#sample-gradient)"
                rx={barWidth / 2}
                ry={barWidth / 2}
              />
            );
          })}
          {/* Point at the end */}
          <circle
            cx={scaleX(maxDate)}
            cy={scaleY(heartRateHistory[heartRateHistory.length - 1].value)}
            r={2}
            fill={theme.accent}
          />
        </g>

        <linearGradient id="area-gradient" x1="0" y1="0" x2="0" y2="1">
          <stop offset="0%" stopColor={rgba(theme.accent, 1)} />
          <stop offset="100%" stopColor={rgba(theme.accent, 0)} />
        </linearGradient>

        <linearGradient id="sample-gradient" x1="0" y1="0" x2="0" y2="1">
          <stop offset="0%" stopColor={rgba(theme.accent, 0.8)} />
          <stop offset="25%" stopColor={rgba(theme.accent, 0.5)} />
          <stop offset="90%" stopColor={rgba(theme.accent, 0)} />
        </linearGradient>
      </svg>
    </Flex>
  );
};
