import React, { FC, PropsWithChildren } from 'react';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import { DarkTheme } from './DarkTheme';
import { DefaultTheme, Theme } from './DefaultTheme';

export interface ThemeProviderProps extends PropsWithChildren {
  theme: Theme;
}

export const ThemeProvider: FC<PropsWithChildren<ThemeProviderProps>> = ({
  theme,
  ...props
}) => <StyledComponentsThemeProvider theme={theme} {...props} />;

export { DarkTheme, DefaultTheme };
export type { Theme };
