import React from 'react';
import { ThemeProvider } from 'styled-components';

import { Leva } from 'leva';
import { Page } from '../ui';
import { GlobalStyle } from '../ui/GlobalStyle';
import { DefaultTheme, Theme } from '../ui/themes/DefaultTheme';
import { useSystemTheme } from './utils/useSystemTheme';

const isDevelopment = process.env.NODE_ENV === 'development';

export interface PlainLayoutProps {
  theme?: Theme;
  children?: React.ReactNode;
  background?: React.ReactNode;
}

export const PlainLayout: React.FC<
  React.PropsWithChildren<PlainLayoutProps>
> = (props) => {
  const { children, theme: staticTheme, background } = props;
  const theme = useSystemTheme(DefaultTheme);

  return (
    <ThemeProvider theme={staticTheme || theme}>
      <GlobalStyle />

      <Page>
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
        >
          {background}
        </div>

        {children}
      </Page>

      <Leva
        theme={{
          colors: {
            elevation1: 'rgba(0, 0, 0, 0.2)',
            elevation2: 'rgba(0, 0, 0, 0)',
            elevation3: 'rgba(255, 255, 255, 0.1)',
            accent1: theme.accent,
            accent2: theme.accent,
            accent3: theme.accent,
            folderTextColor: 'white',
            highlight1: 'rgba(255, 255, 255, 0.5)',
            highlight2: 'rgba(255, 255, 255, 0.5)',
            highlight3: 'rgba(255, 255, 255, 0.5)',
          },
          fontSizes: {
            toolTip: '12rem',
          },
          shadows: {
            level1: 'inset 0 0 2px rgba(255, 255, 255, 0.5)',
          },
        }}
        hidden={!isDevelopment}
      />

      {/*  {isDevelopment && <Stats showPanel={0} />} */}
    </ThemeProvider>
  );
};
