import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Scene } from '../3d/Scene';
import { HeartRateContextualPopup } from '../components/HeartRateContextualPopup/HeartRateContextualPopup';
import { LocationContextualPopup } from '../components/LocationContextualPopup/LocationContextualPopup';
import SEO from '../components/seo';
import { PlainLayout } from '../layouts/PlainLayout';
import { Reveal } from '../ui';
import { DarkTheme } from '../ui/themes';
import { AudioPlayer } from '../components/AudioPlayer/AudioPlayer';
import { WalkmanIntro } from '../3d/WalkmanIntro';
import { useWalkmanStore } from '../3d/useWalkmanState';
import { Letter } from '../components/Letter';

const Index: React.FC = ({ ...props }) => {
  const { isDisappeared: isIntroFinished } = useWalkmanStore();

  const [isZoomedIn, setIsZoomedIn] = useState(false);

  return (
    <PlainLayout
      {...props}
      theme={DarkTheme}
      background={
        isIntroFinished ? (
          <SceneReveal activeOpacity={0.75} inactiveOpacity={0.5} spread={0}>
            <Scene isZoomedIn={isZoomedIn} />
          </SceneReveal>
        ) : (
          <WalkmanIntro />
        )
      }
    >
      <SEO titleOverride="For as long as I'm alive..." />

      {isIntroFinished && (
        <>
          <Letter />

          <IndicatorsContainer>
            <div
              style={{
                position: 'absolute',
                top: 24,
                left: 0,
                transform: 'skew(0deg, 0.01deg) rotateY(8deg)',
                pointerEvents: 'all',
              }}
            >
              <LocationContextualPopup onOpenChange={setIsZoomedIn} />
            </div>

            <div
              style={{
                position: 'absolute',
                right: 32,
                top: 24,
                transform: 'skew(0, -0.01deg) rotateY(-8deg)',
                pointerEvents: 'all',
              }}
            >
              <HeartRateContextualPopup />
            </div>

            <div
              style={{
                position: 'absolute',
                bottom: 16,
                left: 16,
                transform: 'skew(0, -0.01deg) rotateY(8deg)',
                pointerEvents: 'all',
              }}
            >
              <AudioPlayer />
            </div>
          </IndicatorsContainer>
        </>
      )}
    </PlainLayout>
  );
};

const IndicatorsContainer = styled.div`
  position: absolute;
  perspective: 1200px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  @media (max-width: 480px) {
    perspective: 4000px;
  }
`;

// styled reveal into SceneReveal
const SceneReveal = styled(Reveal)`
  height: 100%;
`;

export default Index;
