import {
  Cloud,
  Clouds,
  OrbitControls,
  PerformanceMonitor,
  PerspectiveCamera,
} from '@react-three/drei';
import { Canvas, useFrame, useLoader } from '@react-three/fiber';
import {
  Autofocus,
  Bloom,
  BrightnessContrast,
  EffectComposer,
  Glitch,
  Noise,
  Pixelation,
  Scanline,
  Vignette,
} from '@react-three/postprocessing';
import { folder, Leva, useControls } from 'leva';
import {
  BrightnessContrastEffect,
  GlitchEffect,
  GlitchMode,
  PixelationEffect,
} from 'postprocessing';
import { FC, useMemo, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import {
  BackSide,
  Group,
  MathUtils,
  MeshBasicMaterial,
  PerspectiveCamera as PerspectiveCameraThree,
  TextureLoader,
  Vector2,
  Vector3,
} from 'three';
import { useCalendar } from '../lib/calendar';
import { PalmTree } from './PalmTree';
import { useSystemTheme } from '../layouts/utils/useSystemTheme';

const calculateLST = (
  longitude: number,
  utcHours: number,
  daysSinceJ2000: number
) => {
  // Calculate Local Sidereal Time in degrees
  return (100.46 + 0.985647 * daysSinceJ2000 + longitude + 15 * utcHours) % 360;
};

export const StarmapSkybox: FC = () => {
  const texture = useLoader(TextureLoader, '/textures/starmap_g8k.jpg');
  const { currentCalendarLocation } = useCalendar();
  const { lat: latitude, long: longitude } = currentCalendarLocation ?? {
    lat: 0,
    long: 0,
  };

  const { modifierX, modifierY } = useControls('StarmapSkybox', {
    modifierX: { value: -45, min: -90, max: 360, step: 45 },
    modifierY: { value: 45, min: -90, max: 360, step: 45 },
  });

  const utcHours = new Date().getUTCHours();
  const daysSinceJ2000 = useMemo(() => {
    const j2000 = new Date(Date.UTC(2000, 0, 1, 12, 0, 0));
    const now = new Date();
    const daysDifference = (+now - +j2000) / (1000 * 60 * 60 * 24);
    return daysDifference;
  }, []);

  // Compute the rotations based on latitude and local sidereal time (LST)
  const [xRotation, yRotation] = useMemo(() => {
    // Latitude rotation (tilt in radians)
    const latRotation = (90 - latitude) * (Math.PI / 180);

    // Calculate Local Sidereal Time and convert to radians
    const lstDegrees = calculateLST(longitude, utcHours, daysSinceJ2000);
    const lstRotation = lstDegrees * (Math.PI / 180);

    return [latRotation, lstRotation];
  }, [latitude, longitude, utcHours, daysSinceJ2000]);

  return (
    <>
      <mesh rotation={[xRotation + modifierX, yRotation + modifierY, 0]}>
        <sphereGeometry args={[500, 64, 64]} />
        <meshBasicMaterial map={texture} side={BackSide} />
      </mesh>
    </>
  );
};

export default StarmapSkybox;

const animationSpeed = 0.005;

const PostProcessingEffects = () => {
  const brightnessContrastRef = useRef<BrightnessContrastEffect>(null);
  const brightnessRef = useRef(-8);
  const contrastRef = useRef(1);

  const { enablePostprocessingEffects, bokehScale, focalLength, smoothTime } =
    useControls('PostProcessingEffects', {
      enablePostprocessingEffects: { value: true },
      autofocus: folder({
        bokehScale: { value: 8, min: 0, max: 200 },
        focalLength: { value: 0.05, min: 0, max: 1 },
        smoothTime: { value: 1, min: 0, max: 10 },
      }),
    });

  useFrame(() => {
    brightnessRef.current = MathUtils.lerp(
      brightnessRef.current,
      0,
      animationSpeed
    );
    contrastRef.current = MathUtils.lerp(
      contrastRef.current,
      0,
      animationSpeed
    );
    if (brightnessContrastRef.current) {
      brightnessContrastRef.current.brightness = brightnessRef.current;
      brightnessContrastRef.current.contrast = contrastRef.current;
    }
  });

  // animate pixelation from 50 to 2
  const pixelationRef = useRef<PixelationEffect>(null);
  const pixelationGranularity = useRef(80);
  const glitchRef = useRef<GlitchEffect>(null);
  const glitchStrength = useRef(new Vector2(0, 0.1));
  const glitchColumns = useRef(0.001);
  const time = useRef(0);

  useFrame((state, delta) => {
    time.current += delta;
    pixelationGranularity.current = MathUtils.lerp(
      pixelationGranularity.current,
      2 + Math.sin(time.current) * 5,
      animationSpeed
    );
    glitchStrength.current = new Vector2(
      MathUtils.lerp(glitchStrength.current.x, 0, animationSpeed),
      MathUtils.lerp(glitchStrength.current.y, 0, animationSpeed)
    );
    glitchColumns.current = MathUtils.lerp(
      glitchColumns.current,
      0.001 + Math.sin(time.current) * 0.005,
      animationSpeed
    );

    if (pixelationRef.current) {
      pixelationRef.current.granularity = pixelationGranularity.current;
    }

    if (glitchRef.current) {
      glitchRef.current.strength = glitchStrength.current;
      glitchRef.current.columns = glitchColumns.current;
    }
  });

  return (
    <EffectComposer enabled={enablePostprocessingEffects}>
      <Vignette offset={0.3} darkness={1} eskil={false} />
      <BrightnessContrast
        ref={brightnessContrastRef as any}
        brightness={brightnessRef.current}
        contrast={contrastRef.current}
      />
      <Pixelation
        ref={pixelationRef}
        granularity={pixelationGranularity.current}
      />
      <Noise opacity={0.025} />
      <Bloom
        intensity={20}
        luminanceThreshold={0}
        luminanceSmoothing={1}
        mipmapBlur={true}
      />
      <Glitch
        ref={glitchRef}
        strength={new Vector2(0, 0.1)}
        mode={GlitchMode.CONSTANT_MILD}
        columns={0.001}
        active
        ratio={0.1}
      />
      <Scanline density={1} opacity={0.1} />

      <Autofocus
        bokehScale={bokehScale}
        focalLength={focalLength}
        smoothTime={smoothTime}
      />
    </EffectComposer>
  );
};

const Camera = ({ isZoomedIn }: { isZoomedIn: boolean }) => {
  const ref = useRef<PerspectiveCameraThree>(null);
  const { enableOrbitControls, magnitudeX, magnitudeY } = useControls(
    'Camera',
    {
      enableOrbitControls: { value: false },
      magnitudeX: { value: 0.005, min: 0, max: 1 },
      magnitudeY: { value: 0.01, min: 0, max: 1 },
    }
  );

  const phaseY = 0;
  const phaseX = Math.PI / 2; // 90 degrees out of phase

  const zoomedInPosition = new Vector3(0, 0, -3);

  useFrame(() => {
    if (ref.current) {
      // Smoothly rotate the camera around the y-axis with a sine wave
      ref.current.rotation.y =
        Math.sin(Date.now() * 0.001 + phaseY) * magnitudeY;
      // Smoothly move the camera along the z-axis with a sine wave, out of phase with the y-axis rotation
      ref.current.rotation.x =
        Math.sin(Date.now() * 0.001 + phaseX) * magnitudeX;

      ref.current.position.lerp(
        isZoomedIn ? zoomedInPosition : new Vector3(0, 0, 0),
        0.025
      );

      ref.current.fov = MathUtils.lerp(
        ref.current.fov,
        isZoomedIn ? 80 : 60,
        0.025
      );
      ref.current.updateProjectionMatrix();
    }
  });

  if (enableOrbitControls) {
    return <OrbitControls />;
  }
  return (
    <PerspectiveCamera
      ref={ref}
      makeDefault
      // fov={64}
      // position={position.current}
    />
  );
};

const Cl = ({ isZoomedIn }: { isZoomedIn: boolean }) => {
  const ref = useRef<any>(null);
  const cloudRef = useRef<Group>(null);
  const { bounds, segments, scale, volume, speed, color, rotationSpeed } =
    useControls('Clouds', {
      bounds: { value: [3, 3, 3], min: [0, 0, 0], max: [100, 100, 100] },
      segments: { value: 8, min: 1, max: 100 },
      scale: { value: 1, min: 0, max: 16 },
      volume: { value: 1, min: 0, max: 1 },
      speed: { value: 0.025, min: 0, max: 1 },
      color: { value: 'rgb(64, 64, 64)', label: 'Color' },
      rotationSpeed: { value: 0.0005, min: -0.1, max: 0.1, step: 0.0001 },
    } as any) as any;
  // TODO: Fix types

  useFrame(() => {
    if (ref.current) {
      ref.current.rotation.y += rotationSpeed;
    }
    if (cloudRef.current) {
      cloudRef.current.visible = isZoomedIn ? false : true;
    }
  });

  return (
    <Clouds ref={ref} material={MeshBasicMaterial}>
      <Cloud
        seed={4}
        bounds={bounds}
        segments={segments}
        scale={scale}
        volume={volume}
        speed={speed}
        color={color}
      />
    </Clouds>
  );
};

export const Scene = ({ isZoomedIn }: { isZoomedIn: boolean }) => {
  const [dpr, setDpr] = useState(1.5);

  return (
    <>
      <Canvas dpr={dpr}>
        <PerformanceMonitor
          onIncline={() => setDpr(2)}
          onDecline={() => setDpr(1)}
        />

        <StarmapSkybox />
        <Camera isZoomedIn={isZoomedIn} />
        <PostProcessingEffects />
        <Cl isZoomedIn={isZoomedIn} />

        <PalmTree position={[2.5, 2, 1.5]} rotation={[-Math.PI / 2, 0, 0]} />
        <PalmTree position={[-2, -2, 1.5]} rotation={[-Math.PI / 2, 0, 0]} />
      </Canvas>
    </>
  );
};
