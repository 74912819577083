import styled, { keyframes } from 'styled-components';
import { useHealth } from '../../hooks/useHealth';
import { Flex, Typography } from '../../ui';
import { HeartRateIndicator } from '../HeartRateIndicator/HeartRateIndicator';
import { rgba } from 'polished';
import { useSystemTheme } from '../../layouts/utils/useSystemTheme';

export const HeartRateContextualPopupHandle = () => {
  const theme = useSystemTheme();
  const { heartRate } = useHealth();

  return (
    <Root alignItems="center">
      {/*  <Label>
        <Typography.Caption size="L" opacity={0.5}>
          {heartRate ? 'Still alive' : 'No signs of life'}—
        </Typography.Caption>
      </Label> */}

      {/* <Label>{heartRate ? 'Still alive' : 'No signs of life'}&nbsp;&nbsp;</Label> */}

      <HeartRate>
        <HeartRateIndicator
          heartRate={heartRate}
          size={20}
          fillOpacity={0.25}
        />
      </HeartRate>
    </Root>
  );
};

const Root = styled(Flex)`
  padding: 8px 16px;
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: linear-gradient(
      to right,
      transparent,
      ${({ theme }) => theme.accent},
      ${({ theme }) => theme.accent},
      transparent
    );
  }

  &::after {
    bottom: 0;
    top: auto;
  }
`;

const heartRateFadeIn = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const HeartRate = styled.div`
  opacity: 0;
  animation: ${heartRateFadeIn} 0.5s ease-in-out;
  animation-delay: 2.5s;
  animation-fill-mode: forwards;

  filter: url(#turbulence);
`;

const labelFadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-8px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

const Label = styled(Typography.Caption)`
  animation: ${labelFadeIn} 0.5s ease-in-out;
  animation-delay: 5s;
  animation-fill-mode: forwards;
  color: ${({ theme }) => rgba(theme.foreground, 0.5)};
  font-family: monospace;
  // font-size: 2rem;
`;
